export class ExamsService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param examId
     * @param relations
     * @returns ExamResponseDto
     * @throws ApiError
     */
    getExam(examId, relations) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/exams/{examId}',
            path: {
                'examId': examId,
            },
            query: {
                'relations': relations,
            },
        });
    }
    /**
     * @param examId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    patchExam(examId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/exams/{examId}',
            path: {
                'examId': examId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param examId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    deleteExam(examId, requestBody) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/exams/{examId}',
            path: {
                'examId': examId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    createExam(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/exams',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param formData
     * @returns AttachmentResponseDto
     * @throws ApiError
     */
    addAttachment(id, formData) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/exams/{id}/attachments',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id
     * @returns AttachmentResponseDto
     * @throws ApiError
     */
    getAttachments(id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/exams/{id}/attachments',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param examId
     * @param requestBody
     * @returns ResponseNoteDto
     * @throws ApiError
     */
    addNote(examId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/exams/{examId}/notes',
            path: {
                'examId': examId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param examId
     * @returns OmitTypeClass
     * @throws ApiError
     */
    getNotes(examId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/exams/{examId}/notes',
            path: {
                'examId': examId,
            },
        });
    }
    /**
     * @param id
     * @param noteId
     * @returns void
     * @throws ApiError
     */
    deleteNote(id, noteId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/exams/{id}/notes/{noteId}',
            path: {
                'id': id,
                'noteId': noteId,
            },
        });
    }
    /**
     * @param id
     * @param attachmentId
     * @returns void
     * @throws ApiError
     */
    deleteAttachment(id, attachmentId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/exams/{id}/attachments/{attachmentId}',
            path: {
                'id': id,
                'attachmentId': attachmentId,
            },
        });
    }
    /**
     * @param examId
     * @returns any
     * @throws ApiError
     */
    getReportsByExamId(examId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/exams/{examId}/reports',
            path: {
                'examId': examId,
            },
        });
    }
    /**
     * @param examId
     * @returns any
     * @throws ApiError
     */
    requestTokenForUploadAttachment(examId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/exams/{examId}/attachment-request',
            path: {
                'examId': examId,
            },
        });
    }
    /**
     * @param examId
     * @param formData
     * @returns AttachmentResponseDto
     * @throws ApiError
     */
    startAttachmentUpload(examId, formData) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/exams/{examId}/start-attachment-upload',
            path: {
                'examId': examId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param examId
     * @returns any
     * @throws ApiError
     */
    getExamAlerts(examId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/exams/{examId}/alerts',
            path: {
                'examId': examId,
            },
        });
    }
    /**
     * @param examId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    createExamAlert(examId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/exams/{examId}/alerts',
            path: {
                'examId': examId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param alertId
     * @returns any
     * @throws ApiError
     */
    deleteExamAlertById(alertId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/exams/{examId}/alerts/{alertId}',
            path: {
                'alertId': alertId,
            },
        });
    }
}
