export class CompaniesService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param companyName
     * @param cnpj
     * @param refPhysician
     * @param page
     * @param limit
     * @param orderBy
     * @param orderDirection
     * @returns PaginateCompanyResponse
     * @throws ApiError
     */
    getCompanies(companyName, cnpj, refPhysician, page = 1, limit = 10, orderBy, orderDirection) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/companies',
            query: {
                'page': page,
                'limit': limit,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
                'companyName': companyName,
                'cnpj': cnpj,
                'refPhysician': refPhysician,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    createCompany(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/companies',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    createCompanyV2(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/companies',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param companyId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    patchCompany(companyId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/companies/{companyId}',
            path: {
                'companyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param companyId
     * @returns CompanyResponseDtoWithEmployees
     * @throws ApiError
     */
    getCompany(companyId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/companies/{companyId}',
            path: {
                'companyId': companyId,
            },
        });
    }
    /**
     * @param companyId
     * @param productId
     * @returns any
     * @throws ApiError
     */
    addProductToCompany(companyId, productId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/companies/{companyId}/products/{productId}',
            path: {
                'companyId': companyId,
                'productId': productId,
            },
        });
    }
    /**
     * @param companyId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    createUserTemplate(companyId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/companies/{companyId}/templates',
            path: {
                'companyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param companyId
     * @returns any
     * @throws ApiError
     */
    getReportTemplates(companyId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/companies/{companyId}/templates',
            path: {
                'companyId': companyId,
            },
        });
    }
    /**
     * @param companyId
     * @param templateId
     * @returns any
     * @throws ApiError
     */
    getReportTemplateById(companyId, templateId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/companies/{companyId}/templates/{templateId}',
            path: {
                'companyId': companyId,
                'templateId': templateId,
            },
        });
    }
    /**
     * @param companyId
     * @param templateId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    updateUserTemplate(companyId, templateId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/companies/{companyId}/templates/{templateId}',
            path: {
                'companyId': companyId,
                'templateId': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param companyId
     * @param templateId
     * @returns void
     * @throws ApiError
     */
    deleteLetterhead(companyId, templateId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/companies/{companyId}/letterheads/{templateId}',
            path: {
                'companyId': companyId,
                'templateId': templateId,
            },
        });
    }
    /**
     * @param companyId
     * @returns any
     * @throws ApiError
     */
    getReviewerCandidates(companyId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/companies/{companyId}/reviewers',
            path: {
                'companyId': companyId,
            },
        });
    }
}
