/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var UpdateEmployeeDTO;
(function (UpdateEmployeeDTO) {
    let role;
    (function (role) {
        role["SYSTEM"] = "SYSTEM";
        role["SUPERUSER"] = "SUPERUSER";
        role["ADM"] = "ADM";
        role["PHYSICIAN"] = "PHYSICIAN";
        role["RECEPTIONIST"] = "RECEPTIONIST";
        role["TYPIST"] = "TYPIST";
        role["GUEST"] = "GUEST";
        role["TECHNICIAN"] = "TECHNICIAN";
        role["MANAGER"] = "MANAGER";
        role["READER"] = "READER";
    })(role = UpdateEmployeeDTO.role || (UpdateEmployeeDTO.role = {}));
})(UpdateEmployeeDTO || (UpdateEmployeeDTO = {}));
