export class HealthInsurancesService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @returns HealthInsuranceDTO
     * @throws ApiError
     */
    getHealthInsurances() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/health-insurances',
        });
    }
    /**
     * @param requestBody
     * @returns HealthInsuranceDTO
     * @throws ApiError
     */
    createHealthInsurance(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/health-insurances',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns HealthInsuranceDTO
     * @throws ApiError
     */
    getHealthInsuranceById(id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/health-insurances/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns UpdateHealthInsuranceDTO
     * @throws ApiError
     */
    updateHealthInsurance(id, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/health-insurances/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns void
     * @throws ApiError
     */
    deleteHealthInsurance(id) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/health-insurances/{id}',
            path: {
                'id': id,
            },
        });
    }
}
