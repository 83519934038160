import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import packageJson from '../../package.json'
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';


const AboutDlg = ({ onClose }) => {
    const version = (
        <Typography variant='body2' style={{ color: '#999999' }}>
            {packageJson.version}
        </Typography>        
    )

    return (
      <div>
        <Dialog onClose={onClose} open={true}>
            <DialogTitle onClose={onClose} disableTypography={true}>
                <Typography variant='h6'>
                    Prisma Dicom Viewer
                </Typography>
            </DialogTitle>
            <DialogContent>
              <Grid justify="center" container style={{marginBottom: "20px"}}>
                <Avatar src="/logo/colored.svg"></Avatar> 
              </Grid>
              <Grid justify="center" container>
                <Typography type="primary">
                  Version
                </Typography>  
              </Grid>    
              <Grid justify="center" container>
                <Typography type="secondary">
                  {version}
                </Typography>  
              </Grid>    
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose}>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
      </div>
    )
}

export default AboutDlg
