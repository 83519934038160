export class EmployeesService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param companyId
     * @param roles
     * @param refPhysician
     * @returns EmployeesResponseDto
     * @throws ApiError
     */
    getEmployees(companyId, roles, refPhysician) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/companies/{companyId}/employees',
            path: {
                'companyId': companyId,
            },
            query: {
                'roles': roles,
                'refPhysician': refPhysician,
            },
        });
    }
    /**
     * @param companyId
     * @param userId
     * @returns void
     * @throws ApiError
     */
    blockEmployee(companyId, userId) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/companies/{companyId}/employees/{userId}/block',
            path: {
                'companyId': companyId,
                'userId': userId,
            },
        });
    }
    /**
     * @param companyId
     * @param userId
     * @returns void
     * @throws ApiError
     */
    unlockEmployee(companyId, userId) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/companies/{companyId}/employees/{userId}/unlock',
            path: {
                'companyId': companyId,
                'userId': userId,
            },
        });
    }
    /**
     * @param companyId
     * @param userId
     * @returns void
     * @throws ApiError
     */
    resetPasswordManager(companyId, userId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/companies/{companyId}/employees/{userId}/reset-password',
            path: {
                'companyId': companyId,
                'userId': userId,
            },
        });
    }
    /**
     * @param companyId
     * @param userId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    updateCompanysEmployee(companyId, userId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/companies/{companyId}/employees/{userId}',
            path: {
                'companyId': companyId,
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param companyId
     * @param userId
     * @returns void
     * @throws ApiError
     */
    removeUserFromCompany(companyId, userId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/companies/{companyId}/employees/{userId}',
            path: {
                'companyId': companyId,
                'userId': userId,
            },
        });
    }
    /**
     * @param companyId
     * @param userEmail
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    includeEmployeeInCompany(companyId, userEmail, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/companies/{companyId}/employees/{userEmail}/include',
            path: {
                'companyId': companyId,
                'userEmail': userEmail,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param companyId
     * @param userId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    switchRolesOfUser(companyId, userId, requestBody) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/companies/{companyId}/employees/{userId}/roles',
            path: {
                'companyId': companyId,
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param userId
     * @returns any
     * @throws ApiError
     */
    findCompaniesFromEmployee(userId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/employees/{userId}/companies',
            path: {
                'userId': userId,
            },
        });
    }
}
