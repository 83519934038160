export class BetaService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    assessAvailability(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/beta/availability',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    getCompanies() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/beta/companies',
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    sendJoinRequestToCompanies(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/beta/send-join-clinic',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    createConfigurationsForCompany(id, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/beta/companies/{id}/onboarding',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    createUser(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/beta/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
