export class ReportsService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param requestBody
     * @returns CreateReportResponseDto
     * @throws ApiError
     */
    saveReport(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/reports',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param reportId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    updateReport(reportId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/reports/{reportId}',
            path: {
                'reportId': reportId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param reportId
     * @returns void
     * @throws ApiError
     */
    deleteReport(reportId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/reports/{reportId}',
            path: {
                'reportId': reportId,
            },
        });
    }
    /**
     * @param reportId
     * @returns void
     * @throws ApiError
     */
    signReport(reportId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/reports/{reportId}/sign',
            path: {
                'reportId': reportId,
            },
        });
    }
    /**
     * @param reportId
     * @returns void
     * @throws ApiError
     */
    lockExam(reportId) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/reports/{reportId}/lock',
            path: {
                'reportId': reportId,
            },
        });
    }
    /**
     * @param reportId
     * @returns void
     * @throws ApiError
     */
    unlockExam(reportId) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/reports/{reportId}/unlock',
            path: {
                'reportId': reportId,
            },
        });
    }
    /**
     * @param reportId
     * @param requestBody
     * @returns ReviewerResponseDto
     * @throws ApiError
     */
    addReviewer(reportId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/reports/{reportId}/reviewers',
            path: {
                'reportId': reportId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param reportId
     * @param reviewerId
     * @returns void
     * @throws ApiError
     */
    deleteReviewer(reportId, reviewerId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/reports/{reportId}/reviewers/{reviewerId}',
            path: {
                'reportId': reportId,
                'reviewerId': reviewerId,
            },
        });
    }
    /**
     * @param reportId
     * @returns void
     * @throws ApiError
     */
    approveReport(reportId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/reports/{reportId}/reviews/approve',
            path: {
                'reportId': reportId,
            },
        });
    }
    /**
     * @param reportId
     * @returns void
     * @throws ApiError
     */
    removeApproval(reportId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/reports/{reportId}/reviews/remove-approval',
            path: {
                'reportId': reportId,
            },
        });
    }
    /**
     * @param reportId
     * @returns void
     * @throws ApiError
     */
    sendReportByEmail(reportId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/reports/{reportId}/send-by-email',
            path: {
                'reportId': reportId,
            },
        });
    }
}
