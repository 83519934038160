export class BillingService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param cnpj
     * @returns any
     * @throws ApiError
     */
    getBillingByCnpj(cnpj) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/billing/cnpj/{cnpj}',
            path: {
                'cnpj': cnpj,
            },
        });
    }
    /**
     * @param billingId
     * @param page
     * @param limit
     * @param orderBy
     * @param orderDirection
     * @returns any
     * @throws ApiError
     */
    findAll(billingId, page = 1, limit = 10, orderBy, orderDirection) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/billing/{billing_id}',
            path: {
                'billing_id': billingId,
            },
            query: {
                'page': page,
                'limit': limit,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
            },
        });
    }
}
