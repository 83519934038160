export class ConfigurationsService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param companyId
     * @returns any
     * @throws ApiError
     */
    getConfigurations(companyId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/companies/{companyId}/configurations',
            path: {
                'companyId': companyId,
            },
        });
    }
    /**
     * @param companyId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    createConfiguration(companyId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/companies/{companyId}/configurations',
            path: {
                'companyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param companyId
     * @param configurationId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    patchConfigurations(companyId, configurationId, requestBody) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/companies/{companyId}/configurations/{configurationId}',
            path: {
                'companyId': companyId,
                'configurationId': configurationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
