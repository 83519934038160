/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var REPORT_STATUS_ENUM;
(function (REPORT_STATUS_ENUM) {
    REPORT_STATUS_ENUM["OPEN"] = "OPEN";
    REPORT_STATUS_ENUM["STARTED"] = "STARTED";
    REPORT_STATUS_ENUM["CLOSED"] = "CLOSED";
    REPORT_STATUS_ENUM["SIGNED"] = "SIGNED";
})(REPORT_STATUS_ENUM || (REPORT_STATUS_ENUM = {}));
