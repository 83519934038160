/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var CreateReportResponseDto;
(function (CreateReportResponseDto) {
    let status;
    (function (status) {
        status["OPEN"] = "OPEN";
        status["STARTED"] = "STARTED";
        status["CLOSED"] = "CLOSED";
        status["SIGNED"] = "SIGNED";
    })(status = CreateReportResponseDto.status || (CreateReportResponseDto.status = {}));
})(CreateReportResponseDto || (CreateReportResponseDto = {}));
