export class UsersV2Service {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param page
     * @param limit
     * @param orderBy
     * @param orderDirection
     * @param name
     * @param crm
     * @param isActive
     * @param isOnline
     * @param email
     * @param firstAccess
     * @param crmSituation
     * @param createdAt
     * @param updatedAt
     * @returns UserResponseDto
     * @throws ApiError
     */
    getUsers(page = 1, limit = 10, orderBy, orderDirection, name, crm, isActive, isOnline, email, firstAccess, crmSituation, createdAt, updatedAt) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/users',
            query: {
                'page': page,
                'limit': limit,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
                'name': name,
                'crm': crm,
                'isActive': isActive,
                'isOnline': isOnline,
                'email': email,
                'firstAccess': firstAccess,
                'crmSituation': crmSituation,
                'createdAt': createdAt,
                'updatedAt': updatedAt,
            },
        });
    }
    /**
     * @param requestBody
     * @returns UserResponseDto
     * @throws ApiError
     */
    createUser(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param userId
     * @returns UserResponseDto
     * @throws ApiError
     */
    getUserById(userId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/users/{userId}',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * @param userId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    patchUser(userId, requestBody) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v3/users/{userId}',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param userId
     * @returns any
     * @throws ApiError
     */
    disableUser(userId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/users/{userId}/disable',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * @param userId
     * @returns any
     * @throws ApiError
     */
    enableUser(userId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/users/{userId}/enable',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * @param userId
     * @returns any
     * @throws ApiError
     */
    adminResetUserPassword(userId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/users/{userId}/reset-password',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * @param userId
     * @returns UserCompanyResponseDto
     * @throws ApiError
     */
    getUserOrganizations(userId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/users/{userId}/organizations',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * @param userId
     * @returns UserEmployeeDto
     * @throws ApiError
     */
    getUserEmployeeRelations(userId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/users/{userId}/employee-relations',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * @param userId
     * @returns TextShortcutResponseDto
     * @throws ApiError
     */
    getUserTextShortcuts(userId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/users/{userId}/text-shortcuts',
            path: {
                'userId': userId,
            },
        });
    }
}
