export class StorageService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    postStorage(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/storage',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param refPhysician
     * @param page
     * @param limit
     * @param orderBy
     * @param orderDirection
     * @param accessionNumber
     * @param studyUuid
     * @param patientName
     * @param modalities
     * @param startDate
     * @param endDate
     * @param companyId
     * @param companiesRefPhysician
     * @param prioritizeExamsWithoutReports
     * @param priority
     * @returns any
     * @throws ApiError
     */
    getStorage(refPhysician, page = 1, limit = 10, orderBy, orderDirection, accessionNumber, studyUuid, patientName, modalities, startDate, endDate, companyId, companiesRefPhysician, prioritizeExamsWithoutReports, priority) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/storage',
            headers: {
                'ref-physician': refPhysician,
            },
            query: {
                'page': page,
                'limit': limit,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
                'accessionNumber': accessionNumber,
                'studyUUID': studyUuid,
                'patientName': patientName,
                'modalities': modalities,
                'startDate': startDate,
                'endDate': endDate,
                'companyId': companyId,
                'companiesRefPhysician': companiesRefPhysician,
                'prioritizeExamsWithoutReports': prioritizeExamsWithoutReports,
                'priority': priority,
            },
        });
    }
    /**
     * @param studyUid
     * @returns any
     * @throws ApiError
     */
    getStorageByStudyUId(studyUid) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/storage/{studyUid}',
            path: {
                'studyUid': studyUid,
            },
        });
    }
}
