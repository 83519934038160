/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var Tenant;
(function (Tenant) {
    let type;
    (function (type) {
        type["SQL"] = "SQL";
        type["NO_SQL"] = "NO_SQL";
    })(type = Tenant.type || (Tenant.type = {}));
})(Tenant || (Tenant = {}));
