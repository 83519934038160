export class DefaultService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param examId
     * @returns AttachmentDto
     * @throws ApiError
     */
    getAttachments(examId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/exams/{examId}/attachments',
            path: {
                'examId': examId,
            },
        });
    }
    /**
     * @param id
     * @param formData
     * @returns any
     * @throws ApiError
     */
    addAttachment(id, formData) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/exams/{examId}/attachments/{id}/attachments',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @returns any The Health Check is successful
     * @throws ApiError
     */
    check() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/health',
            errors: {
                503: `The Health Check is not successful`,
            },
        });
    }
    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    create(formData) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/internet-exams',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
}
