export class ExamsV2Service {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @returns ExamV2Dto
     * @throws ApiError
     */
    getExams() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/exams',
        });
    }
}
