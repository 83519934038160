export class UsersService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param name
     * @param cpf
     * @param email
     * @param page
     * @param limit
     * @param orderBy
     * @param orderDirection
     * @returns UserResponsePaginateDto
     * @throws ApiError
     */
    getUsers(name, cpf, email, page = 1, limit = 10, orderBy, orderDirection) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users',
            query: {
                'page': page,
                'limit': limit,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
                'name': name,
                'cpf': cpf,
                'email': email,
            },
        });
    }
    /**
     * @param requestBody
     * @returns UserResponseDto
     * @throws ApiError
     */
    postUser(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    createUserV2(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    getUserInfo() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/myself',
        });
    }
    /**
     * @param id
     * @returns UserResponseDto
     * @throws ApiError
     */
    getUser(id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @param formData
     * @returns void
     * @throws ApiError
     */
    updateUser(id, formData) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/users/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id
     * @param formData
     * @returns void
     * @throws ApiError
     */
    updateSignature(id, formData) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/users/{id}/signature',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    getUserPhrases(id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{id}/phrases',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param userId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    createUserPhrase(userId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/{userId}/phrases',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param phraseId
     * @returns void
     * @throws ApiError
     */
    deleteTextShortcut(phraseId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/{phraseId}/phrases',
            path: {
                'phraseId': phraseId,
            },
        });
    }
    /**
     * @param userId
     * @param textShortcutId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    updateUserPhrase(userId, textShortcutId, requestBody) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/{userId}/phrases/{textShortcutId}',
            path: {
                'userId': userId,
                'textShortcutId': textShortcutId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param auth0UserId
     * @returns LogEventResponse
     * @throws ApiError
     */
    getUserLogs(auth0UserId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{auth0UserId}/logs',
            path: {
                'auth0UserId': auth0UserId,
            },
        });
    }
}
