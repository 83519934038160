export class NotificationsService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @returns any
     * @throws ApiError
     */
    getNotificationForUser() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/notifications',
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    readNotifications(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/notifications',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    getAlertsForUser() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/notifications/alerts',
        });
    }
}
