/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ConfirmPasswordResponseDto;
(function (ConfirmPasswordResponseDto) {
    let status;
    (function (status) {
        status["SUCCESS"] = "SUCCESS";
        status["FAILURE"] = "FAILURE";
    })(status = ConfirmPasswordResponseDto.status || (ConfirmPasswordResponseDto.status = {}));
})(ConfirmPasswordResponseDto || (ConfirmPasswordResponseDto = {}));
