export class ProductsService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param name
     * @param page
     * @param limit
     * @param orderBy
     * @param orderDirection
     * @returns ProductResponse
     * @throws ApiError
     */
    getProducts(name, page = 1, limit = 10, orderBy, orderDirection) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products',
            query: {
                'page': page,
                'limit': limit,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
                'name': name,
            },
        });
    }
    /**
     * @param requestBody
     * @returns ProductResponse
     * @throws ApiError
     */
    createProduct(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns ProductResponse
     * @throws ApiError
     */
    getProduct(id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    updateProduct(id, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/products/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns void
     * @throws ApiError
     */
    deleteProduct(id) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/products/{id}',
            path: {
                'id': id,
            },
        });
    }
}
