/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var Exam;
(function (Exam) {
    let laterality;
    (function (laterality) {
        laterality["NEUTRAL"] = "neutral";
        laterality["LEFT"] = "left";
        laterality["RIGHT"] = "right";
    })(laterality = Exam.laterality || (Exam.laterality = {}));
    let status;
    (function (status) {
        status["PENDING"] = "PENDING";
        status["FINISHED"] = "FINISHED";
        status["ARCHIVED"] = "ARCHIVED";
        status["BLOCKED"] = "BLOCKED";
        status["PREPARED"] = "PREPARED";
        status["IN_PROGRESS"] = "IN_PROGRESS";
    })(status = Exam.status || (Exam.status = {}));
    let priority;
    (function (priority) {
        priority["URGENT"] = "URGENT";
        priority["IMPORTANT"] = "IMPORTANT";
        priority["NORMAL"] = "NORMAL";
    })(priority = Exam.priority || (Exam.priority = {}));
    let modality;
    (function (modality) {
        modality["CR"] = "CR";
        modality["CT"] = "CT";
        modality["DR"] = "DR";
        modality["DX"] = "DX";
        modality["RF"] = "RF";
        modality["RG"] = "RG";
        modality["MG"] = "MG";
        modality["MR"] = "MR";
        modality["OT"] = "OT";
        modality["PT"] = "PT";
        modality["SC"] = "SC";
        modality["US"] = "US";
        modality["CT_SR"] = "CTSR";
    })(modality = Exam.modality || (Exam.modality = {}));
})(Exam || (Exam = {}));
