import { AppClient } from "./client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// @ts-ignore
function promisify(request) {
    return __awaiter(this, void 0, void 0, function* () {
        let retries = 0;
        try {
            const result = yield request();
            return [result, null];
        }
        catch (err) {
            let axiosError = err;
            console.log(axiosError);
        }
    });
}
export function useApi(tokenStr) {
    let token = "";
    if (tokenStr) {
        const [_, splitToken] = tokenStr.split(" ");
        // CASO ELE VENHA SEM O PREFIXO BEARER
        token = splitToken !== null && splitToken !== void 0 ? splitToken : _;
    }
    const appClient = new AppClient({
        BASE: `https://api.mpscloud.com.br`,
        TOKEN: token,
        HEADERS: {
            Accept: 'application/json',
        }
    });
    return {
        baseClient: appClient.request,
        companiesApi: appClient.companies,
        authApi: appClient.authentication,
        convertersApi: appClient.converters,
        employeesApi: appClient.employees,
        employeesApiV2: {
            getEmployeesFromCompany: (companyId) => __awaiter(this, void 0, void 0, function* () {
                return promisify(() => appClient.employeesV2.getEmployeeFromCompany(companyId));
            })
        },
        authApiV2: appClient.authV2,
        examsApi: appClient.exams,
        healthInsurancesApi: appClient.healthInsurances,
        productsApi: appClient.products,
        reportsApi: appClient.reports,
        referringPhysicianApi: appClient.requestingPhysician,
        storageApi: appClient.storage,
        usersApi: appClient.users,
        usersApiV2: {
            patchUser: (userId, patchUserDto) => {
                return promisify(() => appClient.usersV2.patchUser(userId, patchUserDto));
            }
        },
        billingsApi: appClient.billing,
        notificationsApi: appClient.notifications,
        configurationsApi: appClient.configurations,
        betaApi: appClient.beta
    };
}
