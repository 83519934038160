/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var StorageDTO;
(function (StorageDTO) {
    let modality;
    (function (modality) {
        modality["CR"] = "CR";
        modality["CT"] = "CT";
        modality["DR"] = "DR";
        modality["DX"] = "DX";
        modality["RF"] = "RF";
        modality["RG"] = "RG";
        modality["MG"] = "MG";
        modality["MR"] = "MR";
        modality["OT"] = "OT";
        modality["PT"] = "PT";
        modality["SC"] = "SC";
        modality["US"] = "US";
        modality["CT_SR"] = "CTSR";
    })(modality = StorageDTO.modality || (StorageDTO.modality = {}));
})(StorageDTO || (StorageDTO = {}));
