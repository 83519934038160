/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var RoleEnum;
(function (RoleEnum) {
    RoleEnum["SYSTEM"] = "SYSTEM";
    RoleEnum["SUPERUSER"] = "SUPERUSER";
    RoleEnum["ADM"] = "ADM";
    RoleEnum["PHYSICIAN"] = "PHYSICIAN";
    RoleEnum["RECEPTIONIST"] = "RECEPTIONIST";
    RoleEnum["TYPIST"] = "TYPIST";
    RoleEnum["GUEST"] = "GUEST";
    RoleEnum["TECHNICIAN"] = "TECHNICIAN";
    RoleEnum["MANAGER"] = "MANAGER";
    RoleEnum["READER"] = "READER";
})(RoleEnum || (RoleEnum = {}));
