export class AuthV2Service {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param requestBody
     * @returns AuthResponseDto
     * @throws ApiError
     */
    auth(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/auth/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns AuthResponseDto
     * @throws ApiError
     */
    changePassword(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/auth/change-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns AuthResponseDto
     * @throws ApiError
     */
    createFirstPassword(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/auth/first-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    resetUserPassword(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/auth/reset-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns ConfirmPasswordResponseDto
     * @throws ApiError
     */
    confirmUserPassword(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/auth/confirm-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns UserInfoDto
     * @throws ApiError
     */
    getUserInfo() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/auth/user-info',
        });
    }
    /**
     * @returns AuthResponseDto
     * @throws ApiError
     */
    refreshToken() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/auth/refresh-token',
        });
    }
}
