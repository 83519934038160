export class ConvertersService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    convertToPdf(formData) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/converters/pdf',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
}
