/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var MODALITY_ENUM;
(function (MODALITY_ENUM) {
    MODALITY_ENUM["CR"] = "CR";
    MODALITY_ENUM["CT"] = "CT";
    MODALITY_ENUM["DR"] = "DR";
    MODALITY_ENUM["DX"] = "DX";
    MODALITY_ENUM["RF"] = "RF";
    MODALITY_ENUM["RG"] = "RG";
    MODALITY_ENUM["MG"] = "MG";
    MODALITY_ENUM["MR"] = "MR";
    MODALITY_ENUM["OT"] = "OT";
    MODALITY_ENUM["PT"] = "PT";
    MODALITY_ENUM["SC"] = "SC";
    MODALITY_ENUM["US"] = "US";
    MODALITY_ENUM["CT_SR"] = "CTSR";
})(MODALITY_ENUM || (MODALITY_ENUM = {}));
