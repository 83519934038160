export class EmployeesV2Service {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param companyId
     * @returns EmployeesResponseV2Dto
     * @throws ApiError
     */
    getEmployeeFromCompany(companyId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/companies/{companyId}/employees',
            path: {
                'companyId': companyId,
            },
        });
    }
    /**
     * @param companyId
     * @param userEmail
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    includeEmployeeInCompany(companyId, userEmail, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/companies/{companyId}/employees/{userEmail}/include',
            path: {
                'companyId': companyId,
                'userEmail': userEmail,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param companyId
     * @param userId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    adminIncludeEmployeeInCompany(companyId, userId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/companies/{companyId}/employees/{userId}/admin-include-user',
            path: {
                'companyId': companyId,
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
