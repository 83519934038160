/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var UpdateExamDto;
(function (UpdateExamDto) {
    let laterality;
    (function (laterality) {
        laterality["NEUTRAL"] = "neutral";
        laterality["LEFT"] = "left";
        laterality["RIGHT"] = "right";
    })(laterality = UpdateExamDto.laterality || (UpdateExamDto.laterality = {}));
    let priority;
    (function (priority) {
        priority["URGENT"] = "URGENT";
        priority["IMPORTANT"] = "IMPORTANT";
        priority["NORMAL"] = "NORMAL";
    })(priority = UpdateExamDto.priority || (UpdateExamDto.priority = {}));
    let modality;
    (function (modality) {
        modality["CR"] = "CR";
        modality["CT"] = "CT";
        modality["DR"] = "DR";
        modality["DX"] = "DX";
        modality["RF"] = "RF";
        modality["RG"] = "RG";
        modality["MG"] = "MG";
        modality["MR"] = "MR";
        modality["OT"] = "OT";
        modality["PT"] = "PT";
        modality["SC"] = "SC";
        modality["US"] = "US";
        modality["CT_SR"] = "CTSR";
    })(modality = UpdateExamDto.modality || (UpdateExamDto.modality = {}));
})(UpdateExamDto || (UpdateExamDto = {}));
