/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var FileJacketDto;
(function (FileJacketDto) {
    let modalitiesInStudy;
    (function (modalitiesInStudy) {
        modalitiesInStudy["CR"] = "CR";
        modalitiesInStudy["CT"] = "CT";
        modalitiesInStudy["DR"] = "DR";
        modalitiesInStudy["DX"] = "DX";
        modalitiesInStudy["RF"] = "RF";
        modalitiesInStudy["RG"] = "RG";
        modalitiesInStudy["MG"] = "MG";
        modalitiesInStudy["MR"] = "MR";
        modalitiesInStudy["OT"] = "OT";
        modalitiesInStudy["PT"] = "PT";
        modalitiesInStudy["SC"] = "SC";
        modalitiesInStudy["US"] = "US";
        modalitiesInStudy["CT_SR"] = "CTSR";
    })(modalitiesInStudy = FileJacketDto.modalitiesInStudy || (FileJacketDto.modalitiesInStudy = {}));
})(FileJacketDto || (FileJacketDto = {}));
