/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var EXAM_STATUS_ENUM;
(function (EXAM_STATUS_ENUM) {
    EXAM_STATUS_ENUM["PENDING"] = "PENDING";
    EXAM_STATUS_ENUM["FINISHED"] = "FINISHED";
    EXAM_STATUS_ENUM["ARCHIVED"] = "ARCHIVED";
    EXAM_STATUS_ENUM["BLOCKED"] = "BLOCKED";
    EXAM_STATUS_ENUM["PREPARED"] = "PREPARED";
    EXAM_STATUS_ENUM["IN_PROGRESS"] = "IN_PROGRESS";
})(EXAM_STATUS_ENUM || (EXAM_STATUS_ENUM = {}));
