export class PatientsService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param patientId
     * @returns any
     * @throws ApiError
     */
    findAll(patientId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/patients/{patientId}/exams',
            path: {
                'patientId': patientId,
            },
        });
    }
}
