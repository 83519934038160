export class RequestingPhysicianService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param companyId
     * @returns any
     * @throws ApiError
     */
    getRequiringPhysicians(companyId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/companies/{companyId}/requiring-physicians',
            path: {
                'companyId': companyId,
            },
        });
    }
    /**
     * @param companyId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    createRequiringPhysician(companyId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/companies/{companyId}/requiring-physicians',
            path: {
                'companyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param companyId
     * @param reqPhysicianId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    updateRequiringPhysician(companyId, reqPhysicianId, requestBody) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/companies/{companyId}/requiring-physicians/{reqPhysicianId}',
            path: {
                'companyId': companyId,
                'reqPhysicianId': reqPhysicianId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param reqPhysicianId
     * @param companyId
     * @returns any
     * @throws ApiError
     */
    deleteRePhysician(reqPhysicianId, companyId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/companies/{companyId}/requiring-physicians/{reqPhysicianId}',
            path: {
                'reqPhysicianId': reqPhysicianId,
                'companyId': companyId,
            },
        });
    }
}
