import { AxiosHttpRequest } from './core/AxiosHttpRequest';
import { AuthenticationService } from './services/AuthenticationService';
import { AuthV2Service } from './services/AuthV2Service';
import { BetaService } from './services/BetaService';
import { BillingService } from './services/BillingService';
import { CompaniesService } from './services/CompaniesService';
import { ConfigurationsService } from './services/ConfigurationsService';
import { ConvertersService } from './services/ConvertersService';
import { DefaultService } from './services/DefaultService';
import { EmployeesService } from './services/EmployeesService';
import { EmployeesV2Service } from './services/EmployeesV2Service';
import { ExamsService } from './services/ExamsService';
import { ExamsV2Service } from './services/ExamsV2Service';
import { HealthInsurancesService } from './services/HealthInsurancesService';
import { NotificationsService } from './services/NotificationsService';
import { PatientsService } from './services/PatientsService';
import { ProductsService } from './services/ProductsService';
import { ReportsService } from './services/ReportsService';
import { RequestingPhysicianService } from './services/RequestingPhysicianService';
import { StorageService } from './services/StorageService';
import { StorageV2Service } from './services/StorageV2Service';
import { UsersService } from './services/UsersService';
import { UsersV2Service } from './services/UsersV2Service';
export class AppClient {
    constructor(config, HttpRequest = AxiosHttpRequest) {
        var _a, _b, _c, _d;
        this.request = new HttpRequest({
            BASE: (_a = config === null || config === void 0 ? void 0 : config.BASE) !== null && _a !== void 0 ? _a : '',
            VERSION: (_b = config === null || config === void 0 ? void 0 : config.VERSION) !== null && _b !== void 0 ? _b : '2.0.0',
            WITH_CREDENTIALS: (_c = config === null || config === void 0 ? void 0 : config.WITH_CREDENTIALS) !== null && _c !== void 0 ? _c : false,
            CREDENTIALS: (_d = config === null || config === void 0 ? void 0 : config.CREDENTIALS) !== null && _d !== void 0 ? _d : 'include',
            TOKEN: config === null || config === void 0 ? void 0 : config.TOKEN,
            USERNAME: config === null || config === void 0 ? void 0 : config.USERNAME,
            PASSWORD: config === null || config === void 0 ? void 0 : config.PASSWORD,
            HEADERS: config === null || config === void 0 ? void 0 : config.HEADERS,
            ENCODE_PATH: config === null || config === void 0 ? void 0 : config.ENCODE_PATH,
        });
        this.authentication = new AuthenticationService(this.request);
        this.authV2 = new AuthV2Service(this.request);
        this.beta = new BetaService(this.request);
        this.billing = new BillingService(this.request);
        this.companies = new CompaniesService(this.request);
        this.configurations = new ConfigurationsService(this.request);
        this.converters = new ConvertersService(this.request);
        this.default = new DefaultService(this.request);
        this.employees = new EmployeesService(this.request);
        this.employeesV2 = new EmployeesV2Service(this.request);
        this.exams = new ExamsService(this.request);
        this.examsV2 = new ExamsV2Service(this.request);
        this.healthInsurances = new HealthInsurancesService(this.request);
        this.notifications = new NotificationsService(this.request);
        this.patients = new PatientsService(this.request);
        this.products = new ProductsService(this.request);
        this.reports = new ReportsService(this.request);
        this.requestingPhysician = new RequestingPhysicianService(this.request);
        this.storage = new StorageService(this.request);
        this.storageV2 = new StorageV2Service(this.request);
        this.users = new UsersService(this.request);
        this.usersV2 = new UsersV2Service(this.request);
    }
}
