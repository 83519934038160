export class StorageV2Service {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param page
     * @param limit
     * @param orderBy
     * @param orderDirection
     * @param accessionNumber
     * @param studyUuid
     * @param patientName
     * @param modalities
     * @param startDate
     * @param endDate
     * @param companyTokens
     * @param prioritizeExamsWithoutReports
     * @param priority
     * @param onlyExamsWithReports
     * @returns PaginateStorageObjectV2Dto
     * @throws ApiError
     */
    getStorageObject(page = 1, limit = 30, orderBy, orderDirection, accessionNumber, studyUuid, patientName, modalities, startDate = '2023-06-03T19:05:46.687Z', endDate, companyTokens, prioritizeExamsWithoutReports, priority, onlyExamsWithReports) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/storage',
            query: {
                'page': page,
                'limit': limit,
                'orderBy': orderBy,
                'orderDirection': orderDirection,
                'accessionNumber': accessionNumber,
                'studyUUID': studyUuid,
                'patientName': patientName,
                'modalities': modalities,
                'startDate': startDate,
                'endDate': endDate,
                'companyTokens': companyTokens,
                'prioritizeExamsWithoutReports': prioritizeExamsWithoutReports,
                'priority': priority,
                'onlyExamsWithReports': onlyExamsWithReports,
            },
        });
    }
    /**
     * @param studyUid
     * @returns StorageObjectV2DTO
     * @throws ApiError
     */
    getStorageObjectByStudyUid(studyUid) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/storage/{studyUid}',
            path: {
                'studyUid': studyUid,
            },
        });
    }
}
